<template>
  <div class="youtube-video">
    <transition name="fade">
      <youtube
        :video-id="videoId"
        :player-vars="playerVars"
        @ready="ready"
        @buffering="buffering"
        @playing="playing"
        @paused="pause"
        @ended="ended"
        v-if="hasId"
      />
    </transition>

    <transition name="video-thumbnail">
      <div
        :class="[
          'youtube-video__thumbnail',
          {
            'youtube-video__thumbnail--has-overlay': hasId,
          },
        ]"
        :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
        v-if="!isPlaying"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "YoutubeVideo",
  props: {
    videoId: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: String,
      default: null,
    },
    button: {
      type: String,
    },
  },
  data: () => ({
    playerVars: {
      autoplay: 0,
      rel: 0,
      modestbranding: 1,
      origin: window.location.hostname,
    },
    player: null,
    isPlaying: false,
    hasLoaded: false,
    initialClick: false,
  }),
  computed: {
    hasId: (vm) => vm.videoId.length > 0,
  },
  watch: {
    hasLoaded() {
      this.play();
    },
  },
  methods: {
    ready(e) {
      this.player = e;
    },
    playing() {
      this.hasLoaded = true;
      this.isPlaying = true;
    },
    play() {
      if (this.initialClick) {
        this.isPlaying = true;
        setTimeout(() => {
          this.player.playVideo();
          this.$emit("playing");
        }, 900);
      } else {
        this.initialClick = true;
        this.isPlaying = true;
        this.player.playVideo();
        this.$emit("playing");
      }
    },
    pause() {
      this.player.pauseVideo();
      this.$options.delayAnimation = setTimeout(() => {
        this.isPlaying = false;
        this.$emit("pause");
      }, 100);
    },
    stop() {
      this.player.stopVideo();
      this.$options.delayAnimation = setTimeout(() => {
        this.isPlaying = false;
        this.$emit("pause");
      }, 100);
    },
    buffering() {
      clearTimeout(this.$options.delayAnimation);
    },
    ended() {
      this.isPlaying = false;
      this.$emit("pause");
    },
  },
};
</script>

<style lang="scss">
@import "../../../website/scss/base/_nitty.scss";
@import "../../../website/scss/abstracts/variables.scss";
@import "../../../website/scss/abstracts/mixins.scss";

.youtube-video {
  @include aspectratio(356, 563);
  overflow: hidden;
  position: relative;
  transition: opacity 0.4s $t-nice, transform 0.6s $t-nice;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    button.ytp-large-play-button.ytp-button {
      display: none;
    }
  }

  &__thumbnail {
    background: no-repeat center / cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    &--has-overlay:after {
      background-color: rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 26px 107px 29px 66px;
    background: $c-yellow;
    transition: $t-hover;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(50%);
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;

    &-label {
      margin-left: 8px;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.08px;
      line-height: 15px;
      text-transform: uppercase;
    }

    &:hover {
      cursor: pointer;
      background: $c-dark-yellow;
    }
  }
}

//Transitions
.video-thumbnail-enter-active {
  transition: transform 0.5s $t-nice, opacity 0.6s $t-nice;
}

.video-thumbnail-leave-active {
  transition: transform 0.5s $t-nice 0.75s, opacity 0.6s $t-nice 0.75s;
}

.video-thumbnail-enter,
.video-thumbnail-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
