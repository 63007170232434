<template>
  <div class="o-welcome">
    <div class="o-breadcrumb" data-aos="fade-down" data-aos-delay="250">
      <div class="o-breadcrumb__item">Virtuel tour</div>
    </div>

    <div class="o-welcome__wrapper" id="anchor">
      <div class="o-welcome__content" ref="cordLogoLeft">
        <h1
          data-aos="fade-right"
          v-html="
			welcome.title
          "
        ></h1>
        <div class="trumpet" data-aos="fade-right">
		  {{ welcome.content }}
        </div>
      </div>
    </div>

    <div
      class="o-welcome__navigation"
      data-aos="fade-left"
      data-aos-delay="200"
      data-aos-anchor="#anchor"
    >
      <div
        class="o-welcome__navigation-item"
        @click="openSlideIn()"
        ref="cordLogoTop"
      >
        <div class="icon icon--mb">
          <img src="/images/virtual/play-icon.svg" />
          Se video
        </div>
      </div>
      <div
        class="o-welcome__navigation-item o-welcome__navigation-item--yellow"
        @click="close()"
      >
        <div class="icon">
          <img src="/images/virtual/pointer-icon.svg" />
          Start tour
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {};
  },
  props: {
	  welcome: {
		  type: Object,
	  }
  },
  methods: {
    openSlideIn() {
      this.$emit("showWelcome");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "../../../website/scss/base/_nitty.scss";
@import "../../../website/scss/abstracts/variables.scss";
@import "../../../website/scss/abstracts/mixins.scss";


.o-welcome {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#2e2e2e, 0.9);
  z-index: 3;

  h1 {
    color: #fff;
  }

  &__wrapper {
    @include grid-container($row: true);
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__content {
    @include grid-col($width: 6, $push: 1);

    h1 {
      margin-top: 0;
      margin-bottom: 40px;
    }

    .trumpet {
      color: #cfcfcf;
      font-size: 16px;
      letter-spacing: -0.12px;
      line-height: 24px;
    }
  }

  &__navigation {
    border-left: 1px solid #616161;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-item {
      @include aspectratio(175, 500);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.11px;
      line-height: 19px;
      text-transform: uppercase;
      &::before {
        background: $c-white;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all $t-hover;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        img {
          margin-bottom: 14px;
        }
      }

      &:hover {
        cursor: pointer;
        &::before {
          background: darken($c-white, 10);
        }
      }

      &--yellow {
        &::before {
          background: $c-yellow;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        &:hover {
          &::before {
            background: $c-dark-yellow;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1299px) {
  .o-welcome {
    &__navigation {
      max-width: 400px;
    }
  }
}

@media all and (max-width: 1039px) {
  .o-welcome {
    display: flex;
    flex-direction: column;

    &__content {
      @include grid-col($width: 12, $push: 0);
    }

    &__navigation {
      border-left: none;
      position: relative;
      max-width: 100%;

      flex-direction: row;
      &-item {
        width: 50%;
      }
    }
  }
}

@media all and (max-width: 569px) {
  .o-welcome {
    &__content {
      h1 {
        font-size: 31px;
        letter-spacing: -0.24px;
        line-height: 32px;
      }
    }
  }
}

@media all and (max-width: 474px) {
  .o-welcome {
    &__navigation {
      flex-direction: column;
      &-item {
        @include aspectratio(138, 390);
        width: auto;
      }
    }
  }
}
</style>
