// Import CSS
import 'aos/src/sass/aos.scss';
import virtualstyles from '../scss/v-app.scss'

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'

//Import vue components
import AOS from 'aos'
import VueYoutube from 'vue-youtube'
import vuescroll from 'vuescroll';
import VirtualTour from './components/VirtualTour.vue'

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue')

	//Use
	Vue.use(VueYoutube)
	Vue.use(vuescroll, {
		ops: {
		  scrollPanel: {
			scrollingX: true,
			scrollingY: false,
		  }
		},
		name: 'vueScroll' // customize component name, default -> vueScroll
	  });

	// Create our vue instance
	// @ts-ignore
	const vm = new Vue({
		el: '#app',
		delimiters: ['${', '}'],
		components: {
            'VirtualTour': () => import( /* webpackChunkName: "VirtualTour" */ './components/VirtualTour.vue'),
		},
		//mixins: [clickaway],
		data() {
			return {
			}
		},
		props: {},
		methods: {

		},

		computed: {
		},
		created() {
			 AOS.init({
				easing: 'ease-in-out',
				delay: 100,
				once: true,
				startEvent: 'load',
				anchorPlacement: 'top-bottom',
			})

			window.addEventListener('scroll', this.handleScroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		},
		beforeMount: function () { },
		mounted() {
		},
	})

	return vm
}

// Execute async function
main().then((vm) => { })

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept()
}
