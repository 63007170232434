var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tour",
      class: { "tour--started": !_vm.isWelcomeVisible },
      attrs: { id: "app" }
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isLoading
          ? _c("div", { staticClass: "tour__loader" }, [
              _c("div", { staticClass: "lds-roller" }, [
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", { staticClass: "tour__logo", style: _vm.logoStyle }, [
            _c("img", { attrs: { src: "/images/virtual/logo.svg" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tour__map" },
        [
          _c(
            "vue-scroll",
            {
              ref: "vuescroll",
              on: {
                "handle-scroll": _vm.handleScroll,
                "handle-scroll-complete": _vm.handleScrollComplete
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "tour__bg",
                  class: { "tour__bg--zoom": _vm.isWelcomeVisible }
                },
                [
                  _c("img", {
                    staticClass: "tour__bg-img",
                    attrs: { src: "/images/virtual/map.jpg" }
                  }),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "fade" } }, [
                    !_vm.isWelcomeVisible
                      ? _c(
                          "div",
                          { staticClass: "tour__markers" },
                          _vm._l(_vm.points, function(point) {
                            return _c(
                              "div",
                              {
                                key: point.id,
                                staticClass: "o-locationpoint",
                                style: point.style,
                                attrs: {
                                  "data-aos-delay": point.delay,
                                  "data-aos": "zoom-in"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showSlideIn(point)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/images/virtual/pointer.svg" }
                                }),
                                _vm._v(" "),
                                _c("b", { staticClass: "ring1" }),
                                _vm._v(" "),
                                _c("b", { staticClass: "ring2" }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "o-locationpoint__label" },
                                  [_vm._v(_vm._s(point.label))]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tour__navigation" }, [
        _c(
          "a",
          {
            staticClass: "arrow",
            on: {
              click: function($event) {
                return _vm.previousScreen()
              }
            }
          },
          [_c("img", { attrs: { src: "/images/virtual/arrow-left.svg" } })]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "screens" },
          [
            _vm._l(_vm.scrollScreens, function(index) {
              return _c("div", {
                key: index,
                staticClass: "screen",
                on: {
                  click: function($event) {
                    return _vm.scrollTo(index - 1)
                  }
                }
              })
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "indicator",
              style: { left: "calc(2px + " + _vm.scrollPosition + "%)" }
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "arrow",
            on: {
              click: function($event) {
                return _vm.nextScreen()
              }
            }
          },
          [_c("img", { attrs: { src: "/images/virtual/arrow-right.svg" } })]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.isWelcomeVisible
          ? _c(
              "div",
              {
                staticClass: "o-breadcrumb",
                attrs: { "data-aos": "fade-down" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "o-breadcrumb__item o-breadcrumb__item--arrow",
                    on: {
                      click: function($event) {
                        return _vm.showWelcome()
                      }
                    }
                  },
                  [_vm._v("\n          Velkomst\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "o-breadcrumb__item o-breadcrumb__item--link o-breadcrumb__item--white",
                    attrs: { href: _vm.settings.topLink }
                  },
                  [_vm._v(_vm._s(_vm.settings.topLinkText))]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isWelcomeVisible && !_vm.isLoading
            ? _c("Welcome", {
                ref: "welcome",
                attrs: { welcome: _vm.welcome },
                on: {
                  showWelcome: function($event) {
                    return _vm.showWelcomeVideo()
                  },
                  close: function($event) {
                    return _vm.closeWelcome()
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("video-slide-in", {
        attrs: { "is-open": _vm.isSlideInVisible, point: _vm.selectedPoint },
        on: {
          close: function($event) {
            return _vm.hideSlideIn()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }