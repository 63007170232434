var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoSlideIn", class: { "is-open": _vm.isOpen } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isOpen
          ? _c("div", {
              staticClass: "videoSlideIn__bg",
              on: { click: _vm.close }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "videoSlideIn__inner" }, [
        _c(
          "div",
          { staticClass: "videoSlideIn__toolbar", on: { click: _vm.close } },
          [_vm._m(0), _vm._v("\n      Tilbage\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "videoSlideIn__header" },
          [
            _c(
              "div",
              { staticClass: "videoSlideIn__cover" },
              [
                _c("youtube-video", {
                  ref: "video",
                  attrs: {
                    "video-id": _vm.point.youtubeId,
                    thumbnail: _vm.point.cover
                  },
                  on: {
                    playing: function($event) {
                      return _vm.playing()
                    },
                    pause: function($event) {
                      _vm.isPlaying = false
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade" } }, [
              !_vm.isPlaying
                ? _c(
                    "div",
                    {
                      staticClass: "videoSlideIn__button",
                      on: {
                        click: function($event) {
                          return _vm.playVideo()
                        }
                      }
                    },
                    [
                      !_vm.isBuffering
                        ? _c("img", {
                            attrs: { src: "/images/virtual/play-icon.svg" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isBuffering
                        ? _c(
                            "div",
                            { staticClass: "videoSlideIn__button-loader" },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "44",
                                    height: "44",
                                    viewBox: "0 0 44 44",
                                    stroke: "#000"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        "stroke-width": "2"
                                      }
                                    },
                                    [
                                      _c(
                                        "circle",
                                        {
                                          attrs: { cx: "22", cy: "22", r: "1" }
                                        },
                                        [
                                          _c("animate", {
                                            attrs: {
                                              attributeName: "r",
                                              begin: "0s",
                                              dur: "1.8s",
                                              values: "1; 20",
                                              calcMode: "spline",
                                              keyTimes: "0; 1",
                                              keySplines:
                                                "0.165, 0.84, 0.44, 1",
                                              repeatCount: "indefinite"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("animate", {
                                            attrs: {
                                              attributeName: "stroke-opacity",
                                              begin: "0s",
                                              dur: "1.8s",
                                              values: "1; 0",
                                              calcMode: "spline",
                                              keyTimes: "0; 1",
                                              keySplines: "0.3, 0.61, 0.355, 1",
                                              repeatCount: "indefinite"
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "circle",
                                        {
                                          attrs: { cx: "22", cy: "22", r: "1" }
                                        },
                                        [
                                          _c("animate", {
                                            attrs: {
                                              attributeName: "r",
                                              begin: "-0.9s",
                                              dur: "1.8s",
                                              values: "1; 20",
                                              calcMode: "spline",
                                              keyTimes: "0; 1",
                                              keySplines:
                                                "0.165, 0.84, 0.44, 1",
                                              repeatCount: "indefinite"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("animate", {
                                            attrs: {
                                              attributeName: "stroke-opacity",
                                              begin: "-0.9s",
                                              dur: "1.8s",
                                              values: "1; 0",
                                              calcMode: "spline",
                                              keyTimes: "0; 1",
                                              keySplines: "0.3, 0.61, 0.355, 1",
                                              repeatCount: "indefinite"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isBuffering
                        ? _c(
                            "div",
                            { staticClass: "videoSlideIn__button-label" },
                            [_vm._v("\n            Se video\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isBuffering
                        ? _c(
                            "div",
                            { staticClass: "videoSlideIn__button-label" },
                            [_vm._v("\n            Indlæser\n          ")]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "videoSlideIn__content" }, [
          _c("div", [
            _c("h1", { domProps: { innerHTML: _vm._s(_vm.point.title) } }),
            _vm._v(" "),
            _c("div", {
              staticClass: "text",
              domProps: { innerHTML: _vm._s(_vm.point.content) }
            })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("img", { attrs: { src: "/images/virtual/chevron-left.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }