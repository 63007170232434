var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "youtube-video" },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.hasId
            ? _c("youtube", {
                attrs: {
                  "video-id": _vm.videoId,
                  "player-vars": _vm.playerVars
                },
                on: {
                  ready: _vm.ready,
                  buffering: _vm.buffering,
                  playing: _vm.playing,
                  paused: _vm.pause,
                  ended: _vm.ended
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "video-thumbnail" } }, [
        !_vm.isPlaying
          ? _c("div", {
              class: [
                "youtube-video__thumbnail",
                {
                  "youtube-video__thumbnail--has-overlay": _vm.hasId
                }
              ],
              style: { backgroundImage: "url(" + _vm.thumbnail + ")" }
            })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }