var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "o-welcome" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "o-welcome__wrapper", attrs: { id: "anchor" } }, [
      _c("div", { ref: "cordLogoLeft", staticClass: "o-welcome__content" }, [
        _c("h1", {
          attrs: { "data-aos": "fade-right" },
          domProps: { innerHTML: _vm._s(_vm.welcome.title) }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "trumpet", attrs: { "data-aos": "fade-right" } },
          [_vm._v("\n\t\t  " + _vm._s(_vm.welcome.content) + "\n        ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "o-welcome__navigation",
        attrs: {
          "data-aos": "fade-left",
          "data-aos-delay": "200",
          "data-aos-anchor": "#anchor"
        }
      },
      [
        _c(
          "div",
          {
            ref: "cordLogoTop",
            staticClass: "o-welcome__navigation-item",
            on: {
              click: function($event) {
                return _vm.openSlideIn()
              }
            }
          },
          [_vm._m(1)]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "o-welcome__navigation-item o-welcome__navigation-item--yellow",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_vm._m(2)]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "o-breadcrumb",
        attrs: { "data-aos": "fade-down", "data-aos-delay": "250" }
      },
      [
        _c("div", { staticClass: "o-breadcrumb__item" }, [
          _vm._v("Virtuel tour")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon icon--mb" }, [
      _c("img", { attrs: { src: "/images/virtual/play-icon.svg" } }),
      _vm._v("\n          Se video\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/images/virtual/pointer-icon.svg" } }),
      _vm._v("\n          Start tour\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }