<template>
  <div id="app" class="tour" :class="{ 'tour--started': !isWelcomeVisible }">
    <transition name="fade">
      <div class="tour__loader" v-if="isLoading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
    <div class="tour__logo" :style="logoStyle" v-if="!isLoading">
      <img src="/images/virtual/logo.svg" />
    </div>

    <div class="tour__map">
      <vue-scroll
        @handle-scroll="handleScroll"
        @handle-scroll-complete="handleScrollComplete"
        ref="vuescroll"
      >
        <div class="tour__bg" :class="{ 'tour__bg--zoom': isWelcomeVisible }">
          <img class="tour__bg-img" src="/images/virtual/map.jpg" />

          <transition name="fade">
            <div v-if="!isWelcomeVisible" class="tour__markers">
              <div
                class="o-locationpoint"
                v-for="point in points"
                :key="point.id"
                :data-aos-delay="point.delay"
                data-aos="zoom-in"
                :style="point.style"
                @click="showSlideIn(point)"
              >
                <img src="/images/virtual/pointer.svg" />
                <b class="ring1" />
                <b class="ring2" />

                <span class="o-locationpoint__label">{{ point.label }}</span>
              </div>
            </div>
          </transition>
        </div>
      </vue-scroll>
    </div>

    <div class="tour__navigation">
      <a @click="previousScreen()" class="arrow">
        <img src="/images/virtual/arrow-left.svg" />
      </a>
      <div class="screens">
        <div
          v-for="index in scrollScreens"
          :key="index"
          class="screen"
          @click="scrollTo(index - 1)"
        ></div>
        <div
          class="indicator"
          :style="{ left: 'calc(2px + ' + scrollPosition + '%)' }"
        ></div>
      </div>
      <a @click="nextScreen()" class="arrow">
        <img src="/images/virtual/arrow-right.svg" />
      </a>
    </div>

    <transition name="fade">
      <div class="o-breadcrumb" v-if="!isWelcomeVisible" data-aos="fade-down">
        <div
          class="o-breadcrumb__item o-breadcrumb__item--arrow"
          @click="showWelcome()"
        >
          Velkomst
        </div>
        <a
          class="o-breadcrumb__item o-breadcrumb__item--link o-breadcrumb__item--white"
          :href="settings.topLink"
          >{{ settings.topLinkText }}</a
        >
      </div>
    </transition>

    <transition name="fade">
      <Welcome
        ref="welcome"
		:welcome="welcome"
        v-if="isWelcomeVisible && !isLoading"
        @showWelcome="showWelcomeVideo()"
        @close="closeWelcome()"
      />
    </transition>

    <video-slide-in
      :is-open="isSlideInVisible"
      :point="selectedPoint"
      @close="hideSlideIn()"
    ></video-slide-in>
  </div>
</template>

<script>

import Welcome from "./Welcome.vue";
import VideoSlideIn from "./VideoSlideIn.vue";

export default {
  name: "VirtualTour",
  components: {
    Welcome,
    VideoSlideIn,
  },
  props: {
	  points: {
		  type: Array
	  },
	  welcome: {
		  type: Object
	  },
	  settings: {
		  type: Object
	  }
  },
  data() {
    return {
      isLoading: true,
      isWelcomeVisible: true,
      isSlideInVisible: false,
      selectedPoint: {},
      adjustedPoints: [],
      scrollPosition: 0,
      adjustScrollIndex: true,
      indexScrolling: false,
      scrollIndex: 0,
      logoX: 67,
      logoY: 49,
      logoStyle: {
        marginTop: "49px",
        marginLeft: "67px",
      },
      currentIndex: 0,
    };
  },
  computed: {
    scrollScreens() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let newImageWidth = 2500 * (height / 1563);
      return Math.ceil(newImageWidth / width);
    },
  },
  methods: {
    // eslint-disable-next-line
    handleScroll(vertical, horizontal, nativeEvent) {
      let factor = 1 - 1 / this.scrollScreens;
      this.scrollPosition = horizontal.process * factor * 100;

      if (this.adjustScrollIndex) {
        this.scrollIndex = Math.floor(
          this.scrollPosition / (100 / this.scrollScreens)
        );
      }

      if (!this.adjustScrollIndex && this.indexCompleted) {
        this.adjustScrollIndex = true;
        this.indexCompleted = false;
        return;
      }
    },
    // eslint-disable-next-line
    handleScrollComplete(vertical, horizontal) {
      this.indexCompleted = true;
    },

    previousScreen() {
      //If scrolled to start, stop scroll
      if (!this.scrollIndex) {
        return;
      }
      this.scrollTo(this.scrollIndex - 1);
    },

    nextScreen() {
      //If scrolled to end, stop scroll
      if (this.scrollIndex === this.scrollScreens - 1) {
        return;
      }
      this.scrollTo(this.scrollIndex + 1);
    },

    scrollTo(index) {
      this.scrollIndex = index;
      this.adjustScrollIndex = false;
      this.indexCompleted = false;
      this.$refs["vuescroll"].scrollTo(
        {
          x: index * (100 / (this.scrollScreens - 1)) + "%",
        },
        700,
        "easeInOutQuad"
      );
    },

    showSlideIn(point) {
      this.selectedPoint = point;
      this.isSlideInVisible = true;
    },
    showWelcomeVideo() {
      this.showSlideIn(this.welcome);
    },
    showWelcome() {
      this.isWelcomeVisible = true;
      this.$nextTick(function () {
        this.setLogoPosition();
      });
    },
    setLogoPosition() {
      let width = window.innerWidth;
      let top = 0;
      let left = 0;
      this.$nextTick(function () {
        if (width > 1039) {
          top =
            this.$refs.welcome.$refs.cordLogoTop.getBoundingClientRect().top -
            52 -
            34;

          left =
            this.$refs.welcome.$refs.cordLogoLeft.getBoundingClientRect().left +
            12;
        } else {
          top =
            this.$refs.welcome.$refs.cordLogoLeft.getBoundingClientRect().top -
            52 -
            34;

          left =
            this.$refs.welcome.$refs.cordLogoLeft.getBoundingClientRect().left +
            12;
        }

        this.logoStyle = {
          marginTop: top + "px",
          marginLeft: left + "px",
        };
      });
    },
    closeWelcome() {
      this.isWelcomeVisible = false;
      this.logoStyle = {
        marginTop: "67px",
        marginLeft: "49px",
      };
    },
    hideSlideIn() {
      this.isSlideInVisible = false;
    },
    adjustedX(value) {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let aspectRatio = width / height;

      //Full height of image
      if (aspectRatio > 1000 / 754 && aspectRatio < 1000 / 625) {
        let newWidth = 2500 * (height / 1563);
        return "calc((" + (value / (width / newWidth)) * 100 + "%) - 33px)";
      }

      return "calc((" + value * 100 + "%) - 33px)";
    },
    adjustedY(value) {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let aspectRatio = width / height;

      //Full height of image
      if (aspectRatio > 1000 / 754 && aspectRatio < 1000 / 625) {
        return "calc((" + value * 100 + "%) - 33px)";
      }

      return "calc((" + value * 100 + "%) - 33px)";
    },
    adjustedProcentage(percentage) {
      return percentage;
    },
    adjustPoints() {
      Object.keys(this.points).forEach((key) => {
        this.$set(this.points[key], "style", {
          marginLeft: this.adjustedX(this.points[key]["xOffset"]),
          marginTop: this.adjustedY(this.points[key]["yOffset"]),
        });
      });
    },
	setLoading(){
		if(this.isLoading == true){
			this.isLoading = false;
			this.setLogoPosition();
		}
	},
  },
  mounted() {
    this.adjustPoints();

    this.$nextTick(function () {
      // const left = this.$refs.busstop.getBoundingClientRect().left;
      window.addEventListener("load", () => {
        this.isLoading = false;
        this.setLogoPosition();
      });

	  setTimeout(function() {this.setLoading()}.bind(this), 3000)

      window.addEventListener("resize", this.adjustPoints);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.adjustPoints);
  },
};
</script>

<style lang="scss">
@import "../../../website/scss/base/_nitty.scss";
@import "../../../website/scss/abstracts/variables.scss";
@import "../../../website/scss/abstracts/mixins.scss";

.tour {
  height: 100%;
  .__vuescroll {
    .__rail-is-horizontal {
      display: none;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #313131;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
  }

  &__logo {
    position: fixed;
    z-index: 10;
    // top: 67px;
    // left: 49px;
    animation: fadein 2.2s;
    transition: all 0.6s $t-nice;
    img {
      width: 187px;
      filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.3));
    }
  }

  &__map {
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
    z-index: 1;
  }

  &__bg {
    line-height: 0;
    font-size: 0;
  }

  &__markers {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // border-bottom: 2px solid red;

    .o-locationpoint {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &__navigation {
    display: none;
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    align-items: stretch;
    box-sizing: border-box;

    .arrow {
      display: flex;
      align-items: center;
    }

    .screens {
      display: flex;
      position: relative;
      margin: 0 25px;
    }

    .screen {
      height: 40px;
      width: 24px;
      border: 2px solid #ffffff;
      opacity: 0.5;
      margin: 0 2px;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
      }
    }

    .indicator {
      position: absolute;
      top: 0;
      left: 2px;
      background: #fff;
      height: 40px;
      width: 24px;
      box-sizing: border-box;
    }
  }

  .o-breadcrumb {
    z-index: 3;
  }
}

@media all and (min-aspect-ratio: 1000/626),
  all and (max-aspect-ratio: 1000/625) and (min-aspect-ratio: 1000/754) {
  .tour {
    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      transition: all $t-nice;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &-img {
        display: none;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
       background: url(/images/virtual/map.jpg);
        background-position: center;
        background-size: cover;
        transition: all 0.8s $t-nice;
      }

      &--zoom {
        &::before {
          transform: scale(1.2);
          filter: grayscale(100%);
        }
      }
    }
  }
}

@media (max-aspect-ratio: 1000/754) {
  .tour {
    overflow: hidden;

    &__map {
      height: 100%;
      max-height: 100%;
      // border-bottom: 2px solid red;
    }

    &__navigation {
      display: flex;
    }

    &__bg {
      position: relative;
      height: 100%;

      &-img {
        max-height: 100vh;
        height: 100%;
        transition: all 0.8s $t-nice;
        transform-origin: center left;
      }

      &--zoom {
        .tour__bg-img {
          transform: scale(1.2);
          filter: grayscale(100%);
        }
      }
    }
  }
}

@media all and (max-width: 474px) {
  .tour {
    &--started {
      .tour__logo {
        opacity: 0;
        touch-action: none;
      }
    }

    &__logo {
      top: 15px !important;
      left: auto !important;
      right: 32px !important;
    }
  }
}
</style>

