<template>
  <div class="videoSlideIn" :class="{ 'is-open': isOpen }">
    <transition name="fade">
      <div class="videoSlideIn__bg" v-if="isOpen" @click="close"></div>
    </transition>

    <div class="videoSlideIn__inner">
      <div class="videoSlideIn__toolbar" @click="close">
        <div class="arrow">
          <img src="/images/virtual/chevron-left.svg" />
        </div>
        Tilbage
      </div>
      <div class="videoSlideIn__header">
        <div class="videoSlideIn__cover">
          <youtube-video
            ref="video"
            @playing="playing()"
            @pause="isPlaying = false"
            :video-id="point.youtubeId"
            :thumbnail="point.cover"
          ></youtube-video>
        </div>
        <transition name="fade">
          <div
            class="videoSlideIn__button"
            @click="playVideo()"
            v-if="!isPlaying"
          >
            <img src="/images/virtual/play-icon.svg" v-if="!isBuffering" />

            <div class="videoSlideIn__button-loader" v-if="isBuffering">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                stroke="#000"
              >
                <g fill="none" fill-rule="evenodd" stroke-width="2">
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="0s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="0s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                </g>
              </svg>
            </div>

            <div class="videoSlideIn__button-label" v-if="!isBuffering">
              Se video
            </div>

            <div class="videoSlideIn__button-label" v-if="isBuffering">
              Indlæser
            </div>
          </div>
        </transition>
      </div>

      <div class="videoSlideIn__content">
        <div>
          <h1 v-html="point.title"></h1>
          <div class="text" v-html="point.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YoutubeVideo from "./YoutubeVideo.vue";

export default {
  name: "VideoSlideIn",
  components: {
    YoutubeVideo,
  },
  data() {
    return {
      isPlaying: false,
      isBuffering: false,
    };
  },
  props: {
    point: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["video"].stop();
      this.isBuffering = false;
    },

    playing() {
      this.isPlaying = true;
      setTimeout(() => {
        this.isBuffering = false;
      }, 900);
    },

    playVideo() {
      this.isBuffering = true;
      this.$refs["video"].play();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../../website/scss/base/_nitty.scss";
@import "../../../website/scss/abstracts/variables.scss";
@import "../../../website/scss/abstracts/mixins.scss";

.videoSlideIn {
  &__bg {
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 15;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    transition: transform 500ms $t-nice, opacity 300ms $t-nice;
    width: 100%;
    max-width: 563px;
    z-index: 20;

    .is-open & {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &__header {
    position: relative;
  }

  &__toolbar {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.09px;
    line-height: 16px;
    text-transform: uppercase;
    .arrow {
      height: 34px;
      width: 34px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      line-height: 0;
      font-size: 0;
      background: $c-yellow;
      transition: $t-hover;
    }

    &:hover {
      cursor: pointer;
      .arrow {
        background: $c-dark-yellow;
      }
    }
  }

  &__cover {
    line-height: 0;
    font-size: 0;
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &__button {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 6;
    display: inline-flex;
    align-items: center;
    padding-left: 66px;
    width: 247px;
    height: 79px;
    box-sizing: border-box;
    background: $c-yellow;
    transition: $t-hover;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(50%);
    font-size: 0;
    line-height: 0;

    img {
      margin-right: 8px;
    }

    &-label {
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.08px;
      line-height: 15px;
      text-transform: uppercase;
    }

    &-loader {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 24px;
      overflow: visible;
      margin-right: 4px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
      background: $c-dark-yellow;
    }
  }

  &__content {
    position: relative;
    padding: 73px 89px 33px 65px;
    overflow: auto;

    h1 {
      font-size: 31px;
      letter-spacing: -0.24px;
      line-height: 36px;
      margin-top: 0;
      margin-bottom: 18px;
    }

    .text {
      font-size: 15px;
      letter-spacing: -0.12px;
      line-height: 24px;
    }
  }
}

@media all and (max-width: 429px) {
  .videoSlideIn__content {
    padding: 73px 36px 65px;
  }
}
</style>
